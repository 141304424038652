import Navigo from "navigo";

if ("scrollRestoration" in window.history) {
	window.history.scrollRestoration = "manual";
}

const router = new Navigo(`/${process.env.BASE_PATH}`);

router.on("/billboard", () => {
	router.navigate(`/`, { historyAPIMethod: "replaceState" });
});

const { url } = router.getCurrentLocation();
const target = document.getElementById(url);

url === "download" 
	? downloadModal?.show() 
	: target?.scrollIntoView();

router.resolve();

if (window.IntersectionObserver) {
	const observer = new IntersectionObserver((entries) => {
		const { url } = router.getCurrentLocation();

		entries.forEach((entry) => {
			if (entry.isIntersecting && url !== "download") {
				const id = entry.target.getAttribute("id");
				router.navigate(`/${id}`, { historyAPIMethod: "replaceState" });
			}
		});
	});

	const elements = document.querySelectorAll("[navigationable]");
	Array.from(elements).map((element) => observer.observe(element));
}

if (window.MutationObserver) {
	const observer = new MutationObserver(() => {
		downloadModal.classList.contains("visible")
			? router.navigate(`/download`, { historyAPIMethod: "replaceState" })
			: router.navigate(`/`, { historyAPIMethod: "replaceState" });
	});

	observer.observe(downloadModal, { attributes: true });
}
